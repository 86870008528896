import { createCustomTheme, localeDe, setOptions } from '@mobiscroll/react'
import { TZ } from '~/utils/date'

export const setupMobiscroll = () => {
  // register the custom theme for mobiscroll lib
  createCustomTheme('joviva', 'material')

  // set global options for all components used mobiscroll components like EventCalendar or Datepicker
  setOptions({
    locale: localeDe,
    theme: 'joviva',
    dataTimezone: TZ,
    displayTimezone: TZ,
  })
}
